import dynamic from "next/dynamic";
import Head from "next/head";
import React, { useEffect } from "react";

const Rapidream = dynamic(() => import("rapidream"), {
  ssr: false,
});

try {
  window.atob(globalThis.window?.localStorage.user);
} catch {
  globalThis.window?.localStorage.setItem(
    "user",
    window.btoa(globalThis.window?.localStorage.getItem("user"))
  );
}

function DevPage() {
  useEffect(() => {
    document.body.style.margin = "0px";
    // console.log('test')
  }, []);

  const globalCSS = `
    #__next > * {
      min-height: 100vh;
      min-width: 100vw;
      overflow-y: auto;
    }
  `;

  return (
    <>
      <Head>
        <title>Rapidream</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="shortcut icon" type="image/png" href="/assets/favicon.png" />
        <style>{globalCSS}</style>
      </Head>
      <Rapidream />
    </>
  );
}

export default dynamic(() => Promise.resolve(DevPage), {
  ssr: false,
});
